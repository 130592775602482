import FaucetForm from "./components/FaucetForm";
import Contribute from "./components/Contribute";
import ToggleTheme from "./components/ToggleTheme";
import axios, { config } from "./configure";
import "./App.css";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultConfig,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { mainnet } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import configurations from './config.json'
import { createContext, useState, SetStateAction, Dispatch } from "react";

type Context = {
  theme: string;
  toggleTheme: Dispatch<SetStateAction<Context>>;
};

const initialState: Context = {
  theme: "",
  toggleTheme: (): void => {
    throw new Error("toggleTheme function must be overridden");
  },
};

export const ThemeContext = createContext(initialState);

const queryClient = new QueryClient();

const rkConfig = getDefaultConfig({
  appName: configurations.appInfo.appName,
  projectId: configurations.appInfo.walletConnectProjectId,
  chains: [configurations.chainInfo, mainnet],
  // ssr: true
});

function App() {
  const [theme, setTheme] = useState("dark");
  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className="app" id={theme}>
        <WagmiProvider config={rkConfig}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider>
              <FaucetForm axios={axios} config={config} />
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
        {/*<Contribute /> */}
        {/*         <ToggleTheme theme={theme} setTheme={toggleTheme} /> */}
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
